/* global Promise */
import ApiClient from "../../components/api-client";
import * as _ from "lodash";
import PropertyModel from "./PropertyModel";
import logService from "../view-logs/Service";
import util from "../../components/util";
import config from "../../config";


function createClass(name, rules) {
  let style = document.createElement('style');
  style.type = 'text/css';
  document.getElementsByTagName('head')[0].appendChild(style);
  if(!(style.sheet||{}).insertRule)
    (style.styleSheet || style.sheet).addRule(name, rules);
  else
    style.sheet.insertRule(name+"{"+rules+"}",0);
}

const {instance: Api, asJson: resAsJson} = ApiClient;

function formatNumber(decPlaces = 0) {
  decPlaces = Math.pow(10, decPlaces);
  return function (value) {
    if (!isNaN(value)) {
      const abbrev = ["K", "M", "B", "T"];
      for (let i = abbrev.length - 1; i >= 0; i--) {
        const size = Math.pow(10, (i + 1) * 3);
        if (size <= value) {
          value = Math.round(value * decPlaces / size) / decPlaces;
          if ((value === 1000) && (i < abbrev.length - 1)) {
            value = 1;
            i++;
          }
          value += abbrev[i];
          break;
        }
      }
    }
    return value;
  };
}

const makePanel = () => {
  return {
    stockGraphs: [],
    valueAxes: [
      {id: "line", title: "Temperature", axisColor: "#777", position: "left", offset: 0, labelFunction: formatNumber(0)},
      {id: "column", title: "RPM", offset:0, axisColor: "#777", position: "right",  minimum: 0, maxMultiplier: 2.5, labelFunction: formatNumber(1)},
      {id: "enumLine", title: "State", offset:48, axisColor: "#777", position: "right", minimum: 0, maxMultiplier: 1, integersOnly: true, labelFunction: formatNumber(1)},
      {id: "offlineState", title: "Offline State", axisColor: "FAFAFA", position: "right", minimum: 0, maximum:1, integersOnly: true, labelFunction: formatNumber(1)}
    ],
    stockLegend: {enabled: false}
  };
};

const BaseChartConfig = {
  type: "stock",
  theme: "dark",
  syncDataTimestamps: true,
  addClassNames: true,
  mouseWheelZoomEnabled: true,
  zoomOutOnDataSetChange: true,
  zoomOutOnDataUpdate: true,
  listeners: [],
  valueAxesSettings: {
    axisThickness: 3,
    gridAlpha: 0,
    axisAlpha: 0.87,
    inside: false,
    minMaxMultiplier: 1.05
  },
  panelsSettings: {
    marginTop: 10,
    marginLeft: 60, // inside: false requires that to gain some space
    marginRight: 150
  },

  responsive: {
    enabled: true
  },

  chartScrollbarSettings: {
    updateOnReleaseOnly: false,
    backgroundColor: "#aaa",
    graphFillColor: "#595175",
    graphFillAlpha: 0.8,
    gridColor: "#555",
    gridAlpha: 1,
    selectedBackgroundColor: "#444",
    selectedGraphFillAlpha: 1,
    listeners: []
  },

  chartCursorSettings: {
    valueBalloonsEnabled: false,
    graphBulletSize: 1,
    valueLineBalloonEnabled: false,
    valueLineEnabled: false,
    valueLineAlpha: 0.5,
    listeners: []
  },
  dataSets: [],

  stockEvents: [],

  categoryAxesSettings: {
    maxSeries: 0,
    // groupToPeriods: ["ss", "mm", "10mm", "30mm", "hh", "DD", "WW"],
    minPeriod: "ss",
  },

  legend: {
    enabled: false
  },

  panels: [],

  periodSelector: {
    inputFieldsEnabled: false,
    dateFormat: "MM-DD-YYYY",
    periods: [
      {period: "DD", count: 1, label: "Last 24 Hrs"},
      {period: "DD", count: 3, label: "Last 3 Days"},
      {period: "DD", count: 7, label: "Last 7 Days"},
      {period: "DD", count: 15, label: "Last 15 Days"},
      {period: "MAX", label: "MAX"}
    ]
  }
};

export function ResultSet(keyMap, time, records, boundaries, failureBlobPaths, offlineTimes) {
  this.meta = {keyMap, count: time.length};
  this.time = time;
  this.boundaries = boundaries;
  this.records = records;
  this.failureBlobPaths = failureBlobPaths || [];
  this.offlineTimes = offlineTimes;//this can be null for polling data
}
ResultSet.prototype = {
  getRecordAt(index) {
    return Object.assign({}, this.records[index], {time: this.time[index]});
  },
  getSeries(name) {
    return _.map(this.records, this.meta.keyMap[name]);
  },
  merge(resultSet) {
    let time = _.clone(this.time),
        records = _.cloneDeep(this.records),
        meta = _.cloneDeep(this.meta);
    resultSet.records.forEach((rec, index) => {
      const i = time.indexOf(rec.ts);
      if (i !== -1) {
        Object.assign(records[i], rec);
      } else {
        const ts = rec.ts,
            idx = _.findIndex(time, t => t > ts),
            prevRec = records[idx - 1] || {};
        Object.assign(prevRec, _.omit(rec, ["ts"]));
        Object.assign(rec, _.omit(prevRec, ["ts"]));
        records.splice(idx, 0, rec);
        time.splice(idx, 0, rec.ts);
        // time = _.map(records, 'ts');
      }
    });
    meta.count = time.length;
    meta.request.properties = _.unionBy(resultSet.meta.request.properties, meta.request.properties, "id");

    this.records = records;
    this.time = time;
    this.meta = meta;
    
    // Normalize data after merging
    this.normalizeAfterMerge();
  },
  
  normalizeAfterMerge() {
    // Skip if no records or meta data
    if (!this.records || !this.records.length || !this.meta || !this.meta.request || !this.meta.request.properties) {
      return;
    }
    
    // Get all property keys from all records except 'ts' and 'ek'
    const allKeys = new Set();
    this.records.forEach(record => {
      Object.keys(record).forEach(key => {
        if (key !== 'ts' && key !== 'ek') {
          allKeys.add(key);
        }
      });
    });
    
    // Create a reverse mapping from key to property
    const keyToProperty = {};
    this.meta.request.properties.forEach(prop => {
      if (prop.name && this.meta.keyMap[prop.name]) {
        keyToProperty[this.meta.keyMap[prop.name]] = prop;
      }
    });
    
    // Get scales if available
    const scales = this.meta.request.scales || {};
    
    // Normalize each record
    for (let i = 0; i < this.records.length; i++) {
      const record = this.records[i];
      
      // Process each key in the record
      allKeys.forEach(key => {
        // Initialize the key if it doesn't exist in this record
        if (!(key in record)) {
          record[key] = null;
        }
        
        let value = record[key];
        
        // Handle null values by using the last non-null value
        if (value === null || value === undefined) {
          // Find the last non-null value for this key
          for (let j = i - 1; j >= 0; j--) {
            const prevValue = this.records[j][key];
            if (prevValue !== null && prevValue !== undefined) {
              value = prevValue;
              break;
            }
          }
          
          // If still null, default to 0
          if (value === null || value === undefined) {
            value = 0;
          }
          
          // Update the record with the normalized value
          record[key] = value;
        }
        
        // Convert to number if possible
        if (value !== null && value !== undefined) {
          record[key] = isNaN(Number(value)) ? value : Number(value);
        }
        
        // Apply scaling if needed
        const prop = keyToProperty[key];
        if (prop && prop.scale && scales[prop.scale]) {
          record[key] = doScaling(record[key], scales[prop.scale]);
        }
      });
    }
  },
  hasData(...properties) {
    return !!_.every(properties, ({id}) => !!_.find(this.meta.request.properties, {id}));
  }
};

function doScaling(v, scale) {
  const getTableMapping = v => {
    const mapping = scale.values.split("_")
        .reduce((acc, each) => {
          const spl = each.split(",");
          acc[spl[0]] = spl[1];
          return acc;
        }, {})
    ;
    return Number(mapping[v] || mapping.else);
  };

  switch(scale.mode) {
    case "table":
      return getTableMapping(v);
    case "scale":
    case "temperature":
      // TODO: Convert to C or F if "temperature"
      return v * Number(scale.gain || 1) + Number(scale.offset || 0);
    case "delta":
      break;
    case "divisor":
      return Number(scale.numerator || 1) / v;
    case "bitmask":
      return (Number(v) << Number(scale.shift)) & Number(scale.and);
    default:
      return v;
  }
}

function normalizeGraphData(pModelCfg) {
  console.log("pModelCfg", pModelCfg);
  const properties = new PropertyModel(pModelCfg).getAll(),
      scales = pModelCfg.scales;
  pModelCfg.panels.titles.forEach((title,index)=>{
    const offlinePropModel = util.getOfflineStatePropertyModel((-1*index).toString(), index, title); 
    if (!_.find(properties, {name: offlinePropModel.name})) {
      properties.push(offlinePropModel);
    }
  });
  
  let keyMap = {};
  _.map(properties, "name").forEach((vf, index) => keyMap[vf] = "k" + index);

  return ({time, data, boundaries, failureBlobPaths, offlineTimes}) => {
    let keys = _.keys(data);

    let records = time.map((ts, index) => {
      return Object.assign(
        _.reduce(keys, (acc, k) => {
          let v = data[k][index];
          const prop = _.find(properties, {name: k});

          if (!v) {
            //Set last value to next non-null records
            v = index > 0 ? _.findLast(data[k], v => v !== null, index - 1) || 0 : 0;
            //Keep old logic for reference
            //   let nextVal = _.find(data[k], v => v !== null, index + 1);
            //   v = (_.isUndefined(nextVal) && index > 0) ? _.findLast(data[k], v => v !== null, index - 1) || 0 : nextVal || 0;
          }

          v = isNaN(Number(v)) ? v : Number(v);

          if (prop && prop.scale && _.has(scales, prop.scale)) {
            v = doScaling(v, scales[prop.scale]);
          }

          acc[keyMap[k]] = v;
          return acc;
        }, {}),
        {ek: 0, ts}
      );
    });

    return new ResultSet(keyMap, time, records, boundaries, failureBlobPaths, offlineTimes);
  };
}

function randomColor() {
  const chars = "0123456789abcdef".split("");
  return "#" + (_.times(6).map(() => _.sample(chars)).join(""));
}

export default {
  filterSelection: null,
  timeRangeSelection: {},
  timeRangeIndex: 2,
  customTimeRange: {},
  setTimeRangeFilterSelection(timeSelection, timeRangeIndex, customTimeRange) {
    if(timeSelection) {
      this.timeRangeSelection = timeSelection;
      this.timeRangeIndex = timeRangeIndex;
    }
    if(timeRangeIndex === "custom" && customTimeRange) {
      this.customTimeRange = customTimeRange
    }
  },
  setFilterSelection(selection) {
    this.filterSelection = selection;
  },
  getTimeRangeFilterSelection() {
    return {
      timeRangeSelection: this.timeRangeSelection,
      timeRangeIndex: this.timeRangeIndex,
      customTimeRange: this.customTimeRange,
    };
  },
  getFilterSelection(selection) {
    return this.filterSelection;
  },
  fetchConnectedGraphData(applianceId, applianceType, startTime, endTime, filters = [], excludeProps = [], forceRefresh = false) {
    endTime = endTime || new Date().getTime();
    startTime = startTime || (() => {
      let d = new Date();
      d.setDate(endTime.getDate() - 1);
      return d.getTime();
    })();

    return PropertyModel.forModel(applianceType).then(propModel => {
      const headers = {"Accept-Encoding": "gzip"},
          allProps = new PropertyModel(propModel).getAll();
      let properties = [],
          qs = `startTimeMillis=${startTime}&endTimeMillis=${endTime}&forceRefresh=${forceRefresh}`;

      if (filters && filters.length) {
        filters.forEach(f => {
          properties = properties.concat(_.filter(allProps, f));
        });
      }
      if(properties && properties.length && excludeProps && excludeProps.length) {
        excludeProps.forEach(f => {
          _.remove(properties, f);
        });
      }

      if (properties && properties.length) {
        properties = _.uniqBy(properties, "id");
        qs += "&" + (properties.map((p) => {
          if(p.name){
          return "filter=" + p.name;
          } else {
            return "filter=" + p.valueField;
          }
        }).join("&"));

        return Api.get(`/appliances/${applianceId}/connectedgraphdata?${qs}`, {headers})
            .then(resAsJson)
            .then(res=>this.generateDeviceConnectivityStateRecords(res,propModel, startTime, endTime))
            .then(normalizeGraphData(propModel))
            .then(g => {
              g.meta.request = {
                connected: true, startTime, endTime,
                properties
              };
              return g;
            });
      } else {
        return Promise.resolve(null);
      }
    }, errResponse => {
    });
  },

  
  generateDeviceConnectivityStateRecords(res, pModelCfg, startTime, endTime) {
    if(!res.time) res.time = []
    const generateOfflineStateRecords = (propKey, data, times, offlineTimes) => {
      const offlineCheckDeltaInMillis = (config.graphMissingdataIntervalSeconds * 1000) || 360000;
      // Initialize the array for this property if it doesn't exist
      if (!data[propKey]) {
        data[propKey] = _.fill(Array(times.length), null);
      }
      // Sort offline times by start time and end time (ascending)
      if (offlineTimes) {
        offlineTimes.sort((a, b) => a.startTime - b.startTime || a.endTime - b.endTime);
      }
      console.log("offlineTimes by ascending duration", offlineTimes);
      // Adjust offline times to stay within provided time bounds
      if (offlineTimes && offlineTimes.length > 0) {
        // Adjust first record's start time if needed
        if (startTime && offlineTimes[0].startTime < startTime) {
          offlineTimes[0].startTime = parseInt(startTime);
        }
        
        // Adjust last record's end time if needed
        const lastIndex = offlineTimes.length - 1;
        if (endTime && offlineTimes[lastIndex].endTime > endTime) {
          offlineTimes[lastIndex].endTime = parseInt(endTime);
        }
      }
      // Process offline times if they exist
      if (offlineTimes) {
        offlineTimes.forEach(offlinePeriod => {
          // process start time
          let index = times.indexOf(offlinePeriod.startTime);
          if (index === -1) { // time not in times, add it
            const sortedIndex = _.sortedIndex(times, offlinePeriod.startTime);
            times.splice(sortedIndex, 0, offlinePeriod.startTime);

            _.keys(data).forEach(k => {
              if (k !== propKey) {
                data[k].splice(sortedIndex, 0, null); // Insert null at index, shift others right
              }
            });

            data[propKey][sortedIndex] = 1;
            data['ts']
          } else {
            data[propKey][index] = 1;
          }
          // process end time
          index = times.indexOf(offlinePeriod.endTime);
          if (index === -1) { // time not in times, add it
            const sortedIndex = _.sortedIndex(times, offlinePeriod.endTime);
            times.splice(sortedIndex, 0, offlinePeriod.endTime);

            _.keys(data).forEach(k => {
              if (k !== propKey) {
                data[k].splice(sortedIndex, 0, null); // Insert null at index, shift others right
              }
            });

            data[propKey][sortedIndex] = 0;
          } else {
            data[propKey][index] = 0;
          }
        });
      }
      else{
        // polling data will not have offline times
        // offline state display can only works if there are more than or equal to
        // 2 records, else we can't determine offline state of device, so keep graph as is
        
        // process time deltas to determine offline state
        if (times.length >= 2) {
          for (let t = 1; t < times.length; t++) {
            const delta = times[t] - times[t - 1];
            // Only set if not already set by offlineTimes processing
            if (data[propKey][t - 1] === null) {
              data[propKey][t - 1] = delta > offlineCheckDeltaInMillis ? 1 : 0;
            }
          }
          // Handle the last point
          if (data[propKey][times.length - 1] === null) {
            // Use the last known state or default to 0
            data[propKey][times.length - 1] = data[propKey][times.length - 2] || 0;
          }
        } 
        else if (times.length === 1) {
          // If only one point, default to online
          data[propKey][0] = 0;
        }
      }
    };
    
    const properties = new PropertyModel(pModelCfg).getAll();
    pModelCfg.panels.titles.forEach((title,index)=>{
      const offlinePropModel = util.getOfflineStatePropertyModel((-1*index).toString(), index, title); 
      generateOfflineStateRecords(offlinePropModel.name, res.data, res.time, res.offlineTimes);
    });
    return res;
  },

  getTimeZoneId(deviceId) {
    return Api.get(`/timezone/${deviceId}/timezone`,{}).then(resAsJson);
  },

  fetchTimeZones() {
    return Api.get(`/timezone/timezones`,{}).then(resAsJson);
  },

  fetchNonConnectedGraphData(applianceId, applianceType, logType, serial) {
    return PropertyModel.forModel(applianceType, logType).then(propModel => {
      const headers = {"Accept-Encoding": "gzip"},
          qs = `logType=${logType}`;
      let promise;

      if (logType == "Temperature") {
        const data = logService.getLogData();
        if (!data) {
          return Promise.reject();
        }
        promise = Promise.resolve(data);
      } else {
        promise = Api.get(`/appliances/${applianceId}/nonconnectedgraphdata?${qs}`, {headers})
        .then(resAsJson);
      }
      return promise
        .then(normalizeGraphData(propModel))
        .then(g => {
          g.meta.request = {
            connected: false, logType,
            properties: new PropertyModel(propModel).getAll()
          };
          return g;
        });
    }, errResponse => {
    });
  },

  /*
  fetchConnectedGraphData(applianceId, applianceType, startTime, endTime) {
    return PropertyModel.forModel(applianceType).then(propModel => {
      let g = normalizeGraphData(propModel)(MOCK_DATA[applianceId]);
      g.meta.request = {connected: true, startTime, endTime};
      return g;
    });
  },

  fetchNonConnectedGraphData(applianceId, applianceType, logType) {
    return PropertyModel.forModel(applianceType, logType).then(propModel => {
      let g = normalizeGraphData(propModel)(MOCK_DATA[applianceId][logType]);
      g.meta.request = {connected: false, logType};
      return g;
    });
  },
  */

  defaultConnectedChartConfig() {
    return _.cloneDeep(BaseChartConfig);
  },

  defaultGraphPropertyColors() {
    return [
      "#a50026",
      "#d73027",
      "#f46d43",
      "#fdae61",
      "#abd9e9",
      "#74add1",
      "#4575b4",
      "#313695",
      "#543005",
      "#8c510a",
      "#bf812d",
      "#35978f",
      "#01665e",
      "#003c30",
      "#8e0152",
      "#c51b7d",
      "#de77ae",
      "#7fbc41",
      "#4d9221",
      "#276419",
      "#40004b",
      "#762a83",
      "#9970ab",
      "#7f3b08"
    ];
  },

  makeChartConfig({applianceType, mode}, properties, data,
                  prop2Panel = {}, bDataDriven = false) {
    const propModel = PropertyModel.getCached(applianceType, mode);
    if (!(properties && properties.length) || !(data && data.records && data.records.length)) {
      return this.getEmptyChartConfig(propModel);
    }

    let config = _.cloneDeep(BaseChartConfig);

    propModel.panels.sizes.forEach(size => {
      config.panels.push(makePanel());
    });

    const keyMap = data.meta.keyMap,
        keyMapKeys = _.keys(keyMap),
        stateTypes = ["Enum", "Bool"],
        unplottables = ["Text"],
        plottableProps = _.filter(properties, prop => {
          return !_.includes(unplottables, prop.type) &&
              (bDataDriven ? _.includes(keyMapKeys, prop.name) : true)
          ;
        }),
        stateProps = _.filter(plottableProps, prop => _.includes(stateTypes, prop.type)),
        numProps = _.filter(plottableProps, prop => !_.includes(stateTypes, prop.type)),
        fieldMappings = plottableProps.map(prop => ({
          panelIdx: _.has(prop2Panel, keyMap[prop.name]) ?
              Number(prop2Panel[keyMap[prop.name]]) :
              Number(prop.panelIdx || 0),
          color: prop.color,
          event: !!_.find(stateProps, {id: prop.id}),
          fromField: keyMap[prop.name],
          toField: keyMap[prop.name],
          prop
        }));
    const hasRpmType = _.find(numProps,{type:'RPM'}) != null;
    const hasEnumLineType = _.find(numProps,{type:'EnumLine'}) != null;
    if(hasRpmType && hasEnumLineType ){//has at least one RPM
      config.panelsSettings.marginRight = 160;
    }
    else{
      config.panelsSettings.marginRight = 80;
    }

    config.dataSets = [{
      fieldMappings: fieldMappings.concat([
        {panelIdx: 0, hideInLegend: true, color: "#fff", event: false, fromField: "ek", toField: "ek"}
      ]),
      dataProvider: data.records.map(r => Object.assign(r, {ts: new Date(r.ts)})),
      categoryField: "ts"
    }];

    let prop2Graph = {},
        panelCount = 0;
    numProps.forEach(function (prop, index) {
      let axisId = "step",
      suffixUnits = prop.suffixUnits ? ((prop.suffixUnits).replace(/[^\w\s]|_/g, "")).trim() : "";

      switch(prop.type){
        case "RPM":
          axisId =  "column";
          break;
        case "EnumLine":
          axisId = "enumLine";
          break;
        case "OfflineState":
          axisId =  "offlineState";
          break;
        case "Numeric":
          if(suffixUnits.toUpperCase() === "C" || suffixUnits.toUpperCase() === "F") {
            config.panels[0] ? config.panels[0].valueAxes[0] = {id: "line", title: prop.suffixUnits, axisColor: "#777", position: "left", offset: 0, labelFunction: formatNumber(0)} : "";
            config.panels[1] ? config.panels[1].valueAxes[0] = {id: "line", title: prop.suffixUnits, axisColor: "#777", position: "left", offset: 0, labelFunction: formatNumber(0)} : "";
            axisId = "line";
          }
          if(suffixUnits.toUpperCase() === "RPM") {
            config.panels[panelCount] ? config.panels[panelCount].valueAxes[1] = {id: "column", title: prop.suffixUnits, offset:0, axisColor: "#777", position: "right",  minimum: 0, maxMultiplier: 2.5, labelFunction: formatNumber(1)} : "";
            axisId =  "column";
          }
          panelCount = 1;
          break;
        default:
          axisId = "step";
      }
      let panelIdx = _.has(prop2Panel, keyMap[prop.name]) ?
            Number(prop2Panel[keyMap[prop.name]]) : Number(prop.panelIdx || 0),
          panel = config.panels[panelIdx],
          seriesId = "h" + prop.id,
          graphType = "step",
          cfg = {
            id: seriesId,
            bulletSize: 14,
            valueAxis: axisId,
            type: prop.plotAs || graphType,
            dataDateFormat: "YYYY-MM-DDTHH:NN:SS.QQ",
            lineColor: prop.color || randomColor(),
            lineThickness: 1.5,
            useDataSetColors: false,
            valueField: keyMap[prop.name],
            gapField: prop.type !== "OfflineState" ? util.OFFLINE_CONNECTIVITY_STATE_ID: ""
          };
      if (prop.type === "RPM") {
        createClass('.amcharts-graph-' + seriesId + ' .amcharts-graph-stroke',"stroke-dasharray: 5;");
      }
      else if(prop.type === "Numeric"){
        if(prop.suffixUnits === "RPM") {
          createClass('.amcharts-graph-' + seriesId + ' .amcharts-graph-stroke',"stroke-dasharray: 5;");
        }
      }
      else if(prop.type === "EnumLine"){
        createClass('.amcharts-graph-' + seriesId + ' .amcharts-graph-stroke');
         const axis = _.find(panel.valueAxes, {id: 'enumLine'});
         axis.labelFunction = (val)=>{
          return val.toString() || '';//Display No value
         };
         axis.minimum = _.min(axis.minimum, prop.minimum);
         axis.maximum = _.max(axis.maximum, prop.maximum);
         axis.offset = hasRpmType ? 48:0;
      }
      else if( prop.type ===  "OfflineState"){
        //This is hidden axis
        const axis = _.find(panel.valueAxes, {id: 'offlineState'});
        axis.title = "";//Set blank
        axis.axisColor = "#FAFAFA";
        axis.axisAlpha = 1;
        axis.labelsEnabled = false;
        axis.labelFunction = (val)=>{
         return val.toString() || '';//Display No value
        };
        cfg.fillAlphas = 1,
        cfg.fillToAxis = "offlineState";
      }

      if (prop.against) {
        const propCfg = _.find(properties, {id: prop.against}),
            range = data.getSeries(propCfg.name),
            min = _.min(range), max = _.max(range);

        if (min === max) {
          panel.valueAxes = panel.valueAxes.map(va => {
            va.guides = va.guides || [];
            va.guides.push({
              value: min,
              lineAlpha: 0.8,
              lineColor: propCfg.color,
              inside: true,
              label: propCfg.displayName,
              position: "right"
            });
            return va;
          });

          Object.assign(cfg, {
            negativeLineColor: "#67b7dc",
            negativeBase: min
          });
        }
      }

      panel.stockGraphs.push(cfg);
      prop2Graph[prop.id] = seriesId;
    });

    let stockEventSet = [];
    if (stateProps.length) {
      config.dataSets[0].stockEvents = _.reduce(stateProps, (acc, prop) => {
        const key = data.meta.keyMap[prop.name],
            panelIdx = prop.panelIdx || 0,
            graph = prop.targetLine ? prop2Graph[prop.targetLine] || `s${panelIdx}` : `s${panelIdx}`;
        stockEventSet.push({
          panelIdx,
          graph
        });
        if (key) {
          let lastVal;
          data.records.forEach(rec => {
            const disp = prop.displayName,
                vmap = prop.valueMapping;
            if (rec[key] !== undefined && rec[key] !== null && lastVal !== (vmap(rec[key]) || rec[key])) {
              const showOnAxis = !prop.targetLine || prop.targetLine === "x-axis",
                  fromValue = vmap(lastVal) || lastVal,
                  toValue = vmap(rec[key]) || rec[key],
                  description = fromValue && toValue ?
                    `${disp} - ${fromValue} -> ${toValue}` : toValue || undefined;
              acc.push({
                ts: rec.ts,
                date: rec.ts,
                type: showOnAxis ? "pin" : "sign",
                backgroundColor: prop.color || "#85CDE6",
                showOnAxis,
                graph,
                text: "!",
                description
              });
              lastVal = toValue;
            }
          });
        }
        return acc;
      }, []);
    }
    const uniqStockEventSet = _.uniqWith(stockEventSet, _.isEqual);
    if (uniqStockEventSet.length) {
      uniqStockEventSet.forEach((st, ind) => {
        if(_.findIndex(config.panels[st.panelIdx].stockGraphs, {id: st.graph}) == -1) {
          config.panels[st.panelIdx].stockGraphs.push({
            id: st.graph,
            valueAxis: "line",
            type: "step",
            lineColor: "#000",
            lineThickness: 1,
            lineAlpha: 0.2,
            valueField: "ek"
          });
        }
      });
    }
    /*if (data.boundaries) {
      config.panels[0].stockGraphs.push({
        id: "boundary_indicator",
        lineAlpha: 1,
        valueField: "boundary"
      });

      Object.assign(config.chartScrollbarSettings, {
        graph: "boundary_indicator",
        graphType: "column",
        minimum: 0,
        maximum: 1
      });
    }*/
    return config;
  },

  getEmptyChartConfig({panels}) {
    panels = panels || {sizes: [100], titles: [" "]};

    let config = _.cloneDeep(BaseChartConfig);


    panels.sizes.forEach(p => {
      config.panels.push({
        stockGraphs: [{
          id: "dummy",
          title: "",
          type: "step",
          valueField: ""
        }],
        valueAxes: [{
          id: "line", title: " ", axisColor: "#777",
          position: "left", offset: 1, minimum: 0, maximum: 100
        }],
        stockLegend: {enabled: false}
      });
    });

    let ts = new Date(), ts2 = new Date(ts);
    ts2.setDate(ts2.getDate() - 1);
    config.dataSets = [{
      fieldMappings: [{color: "#fff", fromField: "dummy", toField: "dummy", hideInLegend: true}],
      dataProvider: [{dummy: 0, ts}, {dummy: 0, ts: ts2}],
      categoryField: "ts"
    }];

    config.chartCursorSettings.enabled = false;
    config.chartScrollbarSettings.enabled = false;

    return config;
  }
};
