import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5stock from "@amcharts/amcharts5/stock";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
/* eslint no-unused-vars: "off" */
/* eslint linebreak-style: "off" */
/* eslint one-var: "off" */
/**
 * AmChart5GraphComponent class for creating and configuring the date axis for the main panel.
 */
export class AmChart5GraphComponent {

  createGraphRoot(id) {
    
    const root = am5.Root.new(id, {
      autoResize: true
    });
    root.setThemes([
      am5themes_Animated.new(root),
      am5themes_Responsive.new(root),
    ]);
    root.numberFormatter.set("numberFormat", "#,###.00");
    return root;
  } 

  getStockChart(root) {
    const stockChart = root.container.children.push(
      am5stock.StockChart.new(root, {})
    );
    return stockChart;
  }

  getStockPanel(root, panel) {
    const panelObj = am5stock.StockPanel.new(root, {
      wheelY: "zoomX",
      panX: true,
      panY: true,
      height: am5.percent(panel.percentHeight),
      width: am5.percent(96),
      marginTop: 28,
      marginBottom:6
    });
    panelObj.children.push(am5.Label.new(root, {
      text: panel.title,
      fontSize: 14,
      fontWeight: "500",
      textAlign: 'center',
      x: am5.percent(4),
      y: -26,
      centerX: am5.percent(50),
      fill: am5.color("#F0F0F0"),  // Set label color to #444
      background: am5.Rectangle.new(root, {
          fill: am5.color("#464648"),
          fillOpacity: 1,
          cornerRadiusTL: 16,   // Set corner radius directly (in pixels, not percent)
          cornerRadiusTR: 16,
          cornerRadiusBL: 16,
          cornerRadiusBR: 16
        },
      )
    }));
    return panelObj;
  }
  /**
   * Creates and configures the date axis for the main panel.
   * @param {Object} root - The root object for the chart.
   * @return {Object} The date axis object.
   */
  getDateAxis(root) {
    const dateAxis = am5xy.DateAxis.new(root, {
      baseInterval: { timeUnit: "second", count: 1 },
      groupData: false,
      // groupIntervals: [
      //   // { timeUnit: "millisecond", count: 1 },     // 1ms
      //   { timeUnit: "second", count: 1 },          // 1 second
      //   { timeUnit: "minute", count: 1 },          // 1 minute
      //   { timeUnit: "hour", count: 1 },            // 1 hour
      //   { timeUnit: "day", count: 1 }             // 1 day
      // ],
      tooltipDateFormat:"HH:mm:ss.SSS - MMM dd, yyyy",
      dateFormats: {
        millisecond: "HH:mm:ss.SSS",
        second: "HH:mm:ss",
        minute: "HH:mm",
        hour: "HH:mm",
        day: "dd MMM",
        week: "dd MMM",
        month: "MMM yyyy"
      },
      periodChangeDateFormats: {
        millisecond: "HH:mm:ss.SSS",
        second: "HH:mm:ss",
        minute: "HH:mm\ndd MMM",
        hour: "HH:mm\ndd MMM",
        day: "dd MMM",
        week: "dd MMM",
        month: "MMM yyyy"
      },
      renderer: am5xy.AxisRendererX.new(root, {
        minGridDistance: 50,
        cellStartLocation: 0.1,
        cellEndLocation: 0.9
      }),
      tooltip: am5.Tooltip.new(root, {})
    });
    return dateAxis;
  }

  /**
   * Creates and returns a scrollbar for the chart
   * @param {am5.Root} root - The root element of the chart
   * @return {am5.Scrollbar} The created scrollbar
   */
  getDateAxisScrollbar(root, data) {
    const scrollbar = am5xy.XYChartScrollbar.new(root, {
      orientation: "horizontal",
      height: 40,
      layer: 30,
      background: am5.Rectangle.new(root, {
        fill: am5.color(0x000000),
        opacity: 0.80
      })
    });  
    // Add date label based on data.ts
    const sbDateAxis = scrollbar.chart.xAxes.push(am5xy.DateAxis.new(root, {
      baseInterval: { timeUnit: "minute", count: 1 },
      renderer: am5xy.AxisRendererX.new(root, {}),
      groupData: false,
      // groupIntervals: [
      //   { timeUnit: "second", count: 1 },          // 1 second
      //   // { timeUnit: "minute", count: 1 },          // 1 minute
      //   // { timeUnit: "hour", count: 1 },            // 1 hour
      //   // { timeUnit: "day", count: 1 }             // 1 day
      // ],
    dateFormats: {
      millisecond: "HH:mm:ss.SSS",
      second: "HH:mm:ss",
      minute: "HH:mm",
      hour: "HH:mm",
      day: "MMM dd",
      week: "MMM dd",
      month: "MMM",
      year: "yyyy"
    },
    periodChangeDateFormats: {
      millisecond: "HH:mm:ss.SSS",
      second: "HH:mm:ss",
      minute: "HH:mm",
      hour: "HH:mm",
      day: "MMM dd",
      week: "MMM dd",
      month: "MMM",
      year: "yyyy"
    },
    gridIntervals: [
      { timeUnit: "hour", count: 1 },
      { timeUnit: "day", count: 1 }
    ]
    }));

    sbDateAxis.get("renderer").labels.template.setAll({
      height: 25,
      textAlign: "center",
      fill: am5.color(0xFFFFFF),
      fontWeight: "bold",
      rotation: 0,
      centerY: am5.p100,
      centerX: am5.p50,
      paddingLeft:4,
      paddingRight:4,
      inside: true,
      oversizedBehavior: "none"
    });
    sbDateAxis.get("renderer").grid.template.setAll({
      stroke: am5.color(0xFFFFFF),
      strokeOpacity: 0.5
    });

    // Set period divider color to white
    sbDateAxis.get("renderer").axisFills.template.setAll({
      fill: am5.color(0xFFFFFF),
      fillOpacity: 0.05,
      visible: true
    });

    const sbValueAxis = scrollbar.chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {})
    }));

    const sbSeries = scrollbar.chart.series.push(am5xy.LineSeries.new(root, {
      valueYField: "value",
      valueXField: "ts",
      xAxis: sbDateAxis,
      yAxis: sbValueAxis
    }));

    sbSeries.fills.template.setAll({
      visible: true,
      fillOpacity: 0.3
    });

    if (data && data.length > 0) {
      sbSeries.data.setAll(data);
    }
    return scrollbar;
  }

  createValueAxis(root, prop) {
    const { type, suffixUnits, displayName } = prop;
    let axisRenderer, axisSettings, hideAxisAndLabel=false;
    switch (type) {
      case "Temp":
        axisRenderer = am5xy.AxisRendererY.new(root, {
          opposite: false,
        });
        axisSettings = {
          displayName:`Temperature (${suffixUnits})`,
          renderer: axisRenderer,
          // min: 0,
          // max: undefined,
          // strictMinMax: true,
          numberFormat: "#,###.#"
        };
        break
      case "RPM":
        axisRenderer = am5xy.AxisRendererY.new(root, {
          opposite: true,
        });
        axisSettings = {
          displayName:'RPM',
          renderer: axisRenderer,
          // min: 0,
          // max: undefined,
          // strictMinMax: true,
          numberFormat: "#,###.#"
        };
        
        if (suffixUnits?.toUpperCase() === "RPM") {
          axisSettings.maxMultiplier = 2.5;
        }
        break;
      case "Numeric":
        axisRenderer = am5xy.AxisRendererY.new(root, {
          opposite: false,
        });
        axisSettings = {
          displayName:(displayName?.includes("Temp") || displayName?.includes("Temperature") )? `Temperature (${suffixUnits})` : "Numeric",
          renderer: axisRenderer,
          // min: 0,
          // max: undefined,
          // strictMinMax: true,
          numberFormat: "#,###.#"
        };
        break;
      case "Bool":
        axisRenderer = am5xy.AxisRendererY.new(root, {
          opposite: true,
          strictMinMax: true
        });
        axisSettings = {
          displayName:'State',
          renderer: axisRenderer,
          strictMinMax: true,
        };
        break;
      case "EnumLine":
        axisRenderer = am5xy.AxisRendererY.new(root, {
          // cellStartLocation: 0.1,
          // cellEndLocation: 0.9,
          opposite: true,
          strictMinMax: true
        });
        axisSettings = {
          displayName:'State',
          renderer: axisRenderer,
          strictMinMax: true,
        };
        break;
      case "OfflineState":
        axisRenderer = am5xy.AxisRendererY.new(root, {
          opposite: true,
          // cellStartLocation: 0.2,
          // cellEndLocation: 0.8,
          // min: 0,      // Start from 0
          // max: 1,      // Max value is 1
        });
        axisSettings = {
          displayName:'Offline State',
          renderer: axisRenderer,
          strictMinMax: true
        };
        hideAxisAndLabel = false;
        break;
      default:
        axisRenderer = am5xy.AxisRendererY.new(root, {
          opposite: false,
        });
        axisSettings = {
          displayName:'Generic',
          renderer: axisRenderer,
          baseValue: 0,
          numberFormat: "#,###.#",
        };
    }
  
    const valueAxis = am5xy.ValueAxis.new(root, {
      ...axisSettings
    });
    if (!hideAxisAndLabel) {
      if (axisSettings.renderer.get("opposite")) {
        valueAxis.children.push(
          am5.Label.new(root, {
          rotation: 90,
          text: axisSettings.displayName,
          y: am5.p50,
          centerX: am5.p50
        }));
      } else {
          valueAxis.children.unshift(
            am5.Label.new(root, {
            rotation: -90,
            text: axisSettings.displayName,
            y: am5.p50,
            centerX: am5.p50
          }));
      }
    }else{
      //set visiblility to false of value axis
      valueAxis.set("visible", false);
    }
    //set color of axis line
    valueAxis.get("renderer").setAll({
      stroke: am5.color(0x000000),  // Black color for the axis line
      strokeOpacity: 0.6,  // Optional: adjust opacity
      strokeWidth: 2  // Optional: adjust line width
    });
    return valueAxis;
  }

  //create series with required
  createSeries(root, propModel, panelObj, xAxisObj, yAxisObj,xValueField,yValueField) {
    let series;
    const hexColor = propModel.color;
    switch(propModel.type) {
      case "Temp":
        series = panelObj.series.push(am5xy.StepLineSeries.new(root, {
          name: propModel.displayName,
          xAxis: xAxisObj,
          yAxis: yAxisObj,
          valueYField: yValueField,
          valueXField: xValueField,
          opposite: false,
          noRisers: false, 
          fill: am5.color(hexColor),
          stroke: am5.color(hexColor),
          tooltip: am5.Tooltip.new(root, {
            labelText: `${propModel.displayName}: {valueY}`,
            getFillFromSprite: false,
          }), // Set to true for only horizontal lines
        }));
        break
      case "RPM":
        series = panelObj.series.push(am5xy.StepLineSeries.new(root, {
          name: propModel.displayName,
          xAxis: xAxisObj,
          yAxis: yAxisObj,
          valueYField: yValueField,
          valueXField: xValueField,
          opposite: true,
          noRisers: false,
          fill: am5.color(hexColor),
          stroke: am5.color(hexColor),
          tooltip: am5.Tooltip.new(root, {
            labelText: `${propModel.displayName}: {valueY}`,
            getFillFromSprite: false,
          }),
        }));
        series.strokes.template.setAll({
          strokeWidth: 2,
          strokeDasharray: [2, 2], // Creates a dotted pattern
          stroke: am5.color(hexColor) // Set your desired stroke color
        });
        break;
      case "Numeric":
        series = panelObj.series.push(am5xy.StepLineSeries.new(root, {
          name: propModel.displayName,
          xAxis: xAxisObj,
          yAxis: yAxisObj,
          valueYField: yValueField,
          valueXField: xValueField,
          opposite: true,
          noRisers: false,
          fill: am5.color(hexColor),
          stroke: am5.color(hexColor),
          tooltip: am5.Tooltip.new(root, {
            labelText: `${propModel.displayName}: {valueY}`,
            getFillFromSprite: false,
          }),
        }));
        
        if(propModel?.suffixUnits?.toUpperCase() === "RPM"){
          series.strokes.template.setAll({
            strokeWidth: 2,
            strokeDasharray: [2, 2], // Creates a dotted pattern
            stroke: am5.color(hexColor) // Set your desired stroke color
          });
        }
        break;
      case "EnumLine":
      case "Bool":
        series = panelObj.series.push(am5xy.StepLineSeries.new(root, {
          name: propModel.displayName,
          xAxis: xAxisObj,
          yAxis: yAxisObj,
          valueYField: yValueField,
          valueXField: xValueField,
          noRisers: false,
          opposite: true,
          stepDirection: "left",
          fill: am5.color(hexColor),
          stroke: am5.color(hexColor),
          tooltip: am5.Tooltip.new(root, {
            labelText: `${propModel.displayName}: {valueY}`,
            getFillFromSprite: false,
          })
        }));
        break;
      case "OfflineState":
        series = panelObj.series.push(am5xy.StepLineSeries.new(root, {
          name: propModel.displayName,
          xAxis: xAxisObj,
          yAxis: yAxisObj,
          valueYField: yValueField,
          valueXField: xValueField,
          clustered: false,
          fill: am5.color(hexColor),
          stroke: am5.color(hexColor),
          noRisers: false,
          connect: true,
          tension: 0,
          stepDirection: "left",
          tooltip: am5.Tooltip.new(root, {
            labelText: `${propModel.displayName}: {valueY}`,
            getFillFromSprite: false,
          })
        }));
        series.fills.template.setAll({
          visible: true,
          fillOpacity: 0.99,
          fill: am5.color(hexColor),
        });
        break
      default:
        series = panelObj.series.push(am5xy.StepLineSeries.new(root, {
          name: propModel.displayName,
          xAxis: xAxisObj,
          yAxis: yAxisObj,
          valueYField: yValueField,
          valueXField: xValueField,
          noRisers: true,
          // opposite: false,
          fill: am5.color(hexColor),
          stroke: am5.color(hexColor),
          tooltip: am5.Tooltip.new(root, {
            labelText: `${propModel.displayName}: {valueY}`,
             getFillFromSprite: false,
          })
        }));
        break;
    }
    series.setAll({
      groupDataDisabled: true,
      interpolationDuration: 0,
      sequencedInterpolation: false,
      tooltipInterpolationDuration: 0,
      propName: propModel.name,
      // openValueYShow: false,
      // valueYShow: false
    }); 
    return series;
  }

  setupSeriesData(root, dataPoints, seriesObj) {
    if (dataPoints) {
      seriesObj.data.setAll(dataPoints);
    }
    else{
      console.log("No data points provided for the series");
    }
  }

  setupSeriesTooltip(root, propModel, seriesObj) {
    // seriesObj.setAll({
    //   strokeWidth: 2,
    //   stroke: am5.color(propModel.color || am5.colors.next())
    // });
    // Set tooltip background color,you can also set the stroke (border) of the tooltip
    seriesObj.get("tooltip").get("background").setAll({
      fill: am5.color(propModel.color || am5.colors.next()),
      fillOpacity: 0.75,
      stroke: am5.color(propModel.color || am5.colors.next()),
      strokeOpacity: 1,
      strokeWidth: 2
    });
  }

  getXYCursor(root, seriesObj, xAxisObj, yAxisObj) {
    return am5xy.XYCursor.new(root, {
      yAxis: yAxisObj,
      xAxis: xAxisObj,
      behavior: "none",
      // snapToSeries: [seriesObj],
      // snapToSeriesBy: "y!"
    });
  }

  setupCursorEvents(stockChart, setHoverRecord, isGroupData) {
    let lastHoveredX = null;
    let debounceTimer = null;

    function onHoverHandlerForGroupData(panel, x) {
      if (x === lastHoveredX) return; // Avoid processing if x hasn't changed
      lastHoveredX = x;

      let hoveredData = {};
      panel.series.each((series) => {
        const xAxis = panel.xAxes.getIndex(0);
        var item = xAxis.getSeriesItem(series, x);
        if (item && item.dataContext) {
          hoveredData = {
            ...hoveredData,
            ...item.dataContext
          }
        }
      });

      if (setHoverRecord) {
        setHoverRecord(hoveredData);
      }
    }

    function onHoverHandlerForNonGroupData(panel, x) {
      if (x === lastHoveredX) return;
      lastHoveredX = x;
      let hoveredData = {};
      
      panel.series.each((series) => {
        const xAxis = panel.xAxes.getIndex(0);
        const cursorDate = xAxis.positionToValue(x);
        
        // Binary search for the most recent data point before cursor
        const dataItems = series.dataItems;
        let left = 0;
        let right = dataItems.length - 1;
        let nearestDataItem = null;
        
        while (left <= right) {
          const mid = Math.floor((left + right) / 2);
          const midDate = dataItems[mid].get("valueX");
          
          if (midDate <= cursorDate) {
            nearestDataItem = dataItems[mid];
            // Look in upper half for potentially more recent point
            left = mid + 1;
          } else {
            // Point is too far in future, look in lower half
            right = mid - 1;
          }
        }

        // Add the data point if found
        if (nearestDataItem && nearestDataItem.dataContext) {
          hoveredData = {
            ...hoveredData,
            ...nearestDataItem.dataContext
          }
        }
      });

      if (setHoverRecord && Object.keys(hoveredData).length > 0) {
        setHoverRecord(hoveredData);
      }
    }

    stockChart.panels.each((panel) => {
      const cursor = panel.get("cursor");
      if (cursor) {
        cursor.events.on("cursormoved", function(ev) {
          if (debounceTimer) clearTimeout(debounceTimer);
          debounceTimer = setTimeout(() => {
            var positionX = ev.target.getPrivate("positionX");
            var xAxis = panel.xAxes.getIndex(0);
            var x = xAxis.toAxisPosition(positionX);
            if (x !== undefined) {
              isGroupData ? onHoverHandlerForGroupData(panel, x) : onHoverHandlerForNonGroupData(panel, x);
            }
          }, 50); // Adjust this value to balance between responsiveness and performance
        });
      }
    });
  }
}
